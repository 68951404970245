import React, { Fragment, useCallback, useContext } from 'react';
import get from 'lodash/get';
import httpClient from 'ni-ui/httpClient';
import { PropTypes } from 'prop-types';
import { AppContext } from '../../../app-context';
import { usePaymentMethodSetter } from '../payment-methods';
import useStateWithRef from '../../hooks/useStateWithRef';
import CardInput from 'ni-ui/card-input';

export const validate = (card, cvv) => {
  const showCvv = get(card, 'recaptureCsc', '') !== 'false';
  if (showCvv && !/^[0-9]{3}$/.test(cvv)) {
    return ['CVV_ERROR'];
  }
  return [];
};

const SavedCardRecaptureCsc = ({
  card,
  index,
}) => {
  const {
    orderDetails,
    setContextState,
    outletRef,
    orderRef,
    selectedInstallmentOption,
    languageDirection,
  } = useContext(AppContext);

  const [cardState, setCardState, cardStateRef] = useStateWithRef({
    cvv: '',
  });

  const onClickPayNow = useCallback(async () => {
    const {
      cardholderName,
      cardToken,
      expiry,
    } = card;
    const { cvv } = cardStateRef.current;
    const payload = {
      cardholderName,
      cardToken,
      expiry,
      cvv
    };

    const { data } = await httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/saved-card`, payload);

    setContextState({
      orderDetails: {
        ...orderDetails,
        state: data.state,
        order: data.order,
      },
    });
  }, [orderDetails.order, outletRef, orderRef, selectedInstallmentOption]);

  usePaymentMethodSetter({
    onClickPayNow,
    disablePayNow: validate(card, cardState.cvv).length > 0,
    id: index !== undefined ? `SAVEDCARDS${index}` : 'SAVEDCARD',
  });

  const onChangeCardData = useCallback((data) => {
    const oldData = cardStateRef.current;
    if (data.securityCode !== oldData.cvv) {
      setCardState({ cvv: data.securityCode });
    }
  }, []);

  return (
    <Fragment>
      <div style={{ padding: '20px 0' }}>
        <CardInput
          onChange={onChangeCardData}
          direction={languageDirection}
          hideCvv={false}
          showPayerName={true}
          awaitCscRecapture={true}
          allowedCardTypes={[card.scheme]}
          cardData={{
            creditCardNumber: card.maskedPan,
            mm: card.expiry.split('-')[1],
            yy: card.expiry.split('-')[0].slice(-2),
            securityCode: '',
            name: card.cardholderName,
          }}
        />
      </div>
    </Fragment>
  );
};

SavedCardRecaptureCsc.defaultProps = {
  index: undefined,
};

SavedCardRecaptureCsc.propTypes = {
  card: PropTypes.shape({}).isRequired,
  index: PropTypes.number,
};

export default SavedCardRecaptureCsc;
